<template>
  <div class="w100 wtm_statistics_height bg-fff pl-20 pr-20 pt-20 pb-20 autobox">
    <div class="w100 dp-f fw-w">
      <div class="input_box w-250 mb-24">
        <div class="input_title">印章类型：</div>
        <el-select v-model="state.searchKey.sealType" class="input" size="large">
          <el-option v-for="item in state.options" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </div>
      <!-- <div class="input_box w-250 mb-24">
        <div class="input_title">业务类型：</div>
        <el-select v-model="state.searchKey.sealUser" class="input" size="large">
          <el-option v-for="item in state.optionsType" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </div> -->
      <div class="input_box w-400 mb-24">
        <div class="input_title">选择用印时间：</div>
        <el-date-picker v-model="state.searchKey.dateTime" type="datetimerange" start-placeholder="开始日期"
          end-placeholder="结束日期" value-format="YYYY-MM-DD HH:mm:ss"/>
      </div>
      <oabutton width='80' height='40' title="搜索" CSStype=2 style="border-radius:4px;" class="ml-12"
        @buttonclick="getDataList"></oabutton>
    </div>
    <statistics class="mb-40" :datas="state.statisticsData" v-loading="countLoading"
      :element-loading-text="config.loading.text" :element-loading-spinner="config.loading.spinner"
      :element-loading-background="config.loading.background"></statistics>
    <el-table :data="state.Notice" class="mb-20 table" v-loading="loading" :element-loading-text="config.loading.text"
      :element-loading-spinner="config.loading.spinner" :element-loading-background="config.loading.background"
      :header-cell-style="{
        background: 'var(--active-alpha)',
        color: config.table.color,
        fontWeight: config.table.fontWeight,
        fontSize: config.table.fontSize,
      }" row-key="id">
      <el-table-column :align="'center'" prop="contractName" label="合同名称" />
      <el-table-column :align="'center'" prop="sealName" label="印章名称" />
      <el-table-column :align="'center'" prop="sealNo" label="印章编号" />
      <el-table-column :align="'center'" label="印章状态">
        <template #default="scope">
          <div class="dp-fc contractStatus" :class="'contractStatus' + scope.row.sealStatus">
            <div class="line"></div>
            <div class="w-6 h-6 icon mr-9"></div>
            <div class="w-70 ta-l">{{ getsignStatus(scope.row.sealStatus) }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column :align="'center'" prop="name2" label="印章类型">
        <template #default="scope">
          <div class="w-70 ta-l">{{ getsealtype(scope.row.sealType) }}</div>
        </template>
      </el-table-column>
      <el-table-column :align="'center'" prop="name2" label="申请人" />
      <el-table-column :align="'center'" prop="authorizedId" label="授权人" />
      <el-table-column :align="'center'" prop="userChName" label="用印人" />
      <el-table-column :align="'center'" prop="makeSealCount" label="用印次数" />
      <el-table-column :align="'center'" prop="name2" label="用印时间" />
      <el-table-column :align="'center'" prop="applyDate" label="发起申请时间" />
      <el-table-column width="80" :align="'center'" prop="name2" label="完成" />
    </el-table>
    <el-pagination class="dp-f jc-c" @current-change="handleCurrentChange" background layout="prev, pager, next,slot"
      :current-page="currentPage" :total="totalPage">
    </el-pagination>
  </div>
</template>
<script setup>
import { onMounted, reactive, ref, unref } from "vue";
import { getcontractStatus } from "@/utils/server/getcode.js";
import { httpToken } from "@/utils/request";
import statistics from "../components/statistics.vue";
import { getsignStatus, getsealtype } from '@/utils/server/getcode'

import { getworkTypeData, sealType } from '@/utils/server/selectdata'
import { queryResourceTable } from "@/api/cancelToken.js";
import qs from 'qs'
const loading = ref(false); //loading 显示
const countLoading = ref(false);
const currentPage = ref(1); //当前页数
const totalPage = ref(0); //总条数
const state = reactive({
  searchKey: {
    sealType: "",
    sealUser: "",
    dateTime: null, //日期
  }, //搜索绑定值
  options: sealType(undefined,true), //下拉数据
  optionsType: [],
  Notice: [], //表格数据
  statisticsData: [
    { title: "全部印章", count: 0, icon: "img1.png" },
    { title: "使用中", count: 0, icon: "img6.png" },
    { title: "用印文件数", count: 0, icon: "img9.png" },
    { title: "用印次数", count: 0, icon: "img5.png" },
  ], //统计数据
});
//业务类型内容
getworkTypeData().then(res => {
  state.optionsType = res
})

//获取顶部统计
const getCountData = (type) => {
  countLoading.value = true;
  httpToken({
    url: "seal/seal/sealCount",
    method: "POST",
  })
    .then((res) => {
      state.statisticsData[0].count = res.data.sealCount;
      state.statisticsData[1].count = res.data.makeSealCount;
      state.statisticsData[2].count = res.data.contractCount;
      state.statisticsData[3].count = res.data.makeCount;
      countLoading.value = false;
    })
    .catch((err) => {
      countLoading.value = false;
    });
};
getCountData();
//获取列表数据
const getDataList = (val = 1) => {
  state.Notice = [];
  currentPage.value = val;
  loading.value = true;
  queryResourceTable(
    "/seal/seal/sealList",
    qs.stringify({
      page: val,
      sealType: state.searchKey.sealType,
      sealUser: state.searchKey.sealUser,
      dateTime: JSON.stringify(state.searchKey.dateTime),
    })
  )
    .then((res) => {
      // 如果存在请求页码和返回数据时页面不同的情况 按返回页码重新请求
      if (val !== res.data.current) {
        getDataList(res.data.current);
        return;
      }
      totalPage.value = res.data.total;
      state.Notice = res.data.records;
      loading.value = false;
    })
    .catch((err) => {
      if (err.message != "stop") loading.value = false;
    });
};
const handleCurrentChange = ((val) => {
  getDataList(val)
})
onMounted(() => {
  getDataList()
});
</script>
<style lang="scss" scoped >
// 重新定义最小高度
.wtm_height {
  min-height: calc(100vh - 148px - 46px);
}

::v-deep .contractStatus {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
}

::v-deep .contractStatus1 {
  color: #ff4d4f;
}

.table {
  min-height: 350px;
}
</style>